@font-face {
  font-family: "Poppins-Regular";
  src: url("fonts/Poppins/Poppins-Regular.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("fonts/Poppins/Poppins-Light.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("fonts/Poppins/Poppins-SemiBold.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("fonts/Poppins/Poppins-Bold.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("fonts/DMSans/DMSans-Regular.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("fonts/DMSans/DMSans-Medium.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("fonts/DMSans/DMSans-Bold.ttf");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
